import React from 'react';
import Logo from '../../assests/images/SAWERA TEXTILES.png';
import styles from './style.css'
import YouTubeIcon from '../../assests/svgs/youTubeIcon/index';
import FacebookIcon from '../../assests/svgs/facebookIcon/index'
const Footer=()=>{
    return(
        <>
            <div className="footerMain">
                <div className="footerMainInner">
                    <div className="footerTop">
                        <div className="footerTopImg">
                            <a className="footerImgMain" style={{display:'block'}} href="/">
                                <img src={Logo} alt="Logo" className="footerImg"/>
                            </a>
                        </div>
                        <div className="footerTopContent">
                            <div className="footerContact">
                                <div className="footerContactHead">
                                    <span className="footerContactHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="25px" height="25px" fill="white" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalPhoneIcon" tabindex="-1" title="LocalPhone"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg></span>
                                    <span className="footerContactHeadTxt">Contact Us</span>
                                </div>
                                <div className="footerContactDesc">
                                    <div className="footerContactDescHead"><span>CEO/Marketing Director</span></div>
                                    <div className="footerContactDescTxt"><span>Muhammad Afzal</span></div>
                                </div>
                                <div className="footerContactNo"><span>+92 314 9675190</span></div>
                            </div>
                            <div className="footerMail">
                                <div className="footerMailHead">
                                    <span  className="footerMailHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="25px" height="25px" fill="white" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="MailOutlineIcon" tabindex="-1" title="MailOutline"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path></svg></span>
                                    <span  className="footerMailHeadTxt">Mail Us</span>
                                </div>
                                <div className="footerMailDesc">
                                    <div  className="footerMailDescHead"><a className="footerMailHeadLink">saweratextiles@gmail.com</a></div>
                                    <div className="footerMailDescAddress"><span>SAWERA TEXTILES PVT. LTD.</span></div>
                                    <div className="footerMailDescAddress footerMailHead"><span>By Pass Road Near Asif Petrol Pump<br/> Rahim Yar Khan</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerBottom">
                        <div className="footerBottomTxt"><span>© 2020 SAWERA TEXTILES PVT. LTD. All rights reserved. </span></div>
                        <div className="footerBottomIconsMain">
                            <div className="youTubeIcn"><span><YouTubeIcon /></span></div>
                            <div className="ms-3 facebookIcn"><span><FacebookIcon/></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Footer;