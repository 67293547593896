import React from 'react';
import Banner5 from '../../../assests/images/banner/banner5.png';
import styles from './style.css';
import { Progress } from 'antd';
const Philosophy=()=>{
    return(
        <>
            <div className="phMain">
                <div className="phMainBgImg">
                    <div className="phMainInner">
                        <div className="phDesc animate" data-animate="fadeInUp 2s">
                            <h1 className="phDescHead">Our philosophy</h1>
                            <div className="phDescTxtMain">
                                <p className="phDescTxt">Based on the fact that working as a team with all our Distributors/Dealers. This will bring much-needed assistance to end-users, and strength for every body's growth.</p>
                            </div>
                        </div>
                        <div className="phBarsSecMain">
                        <h1 className="custom-text">Creative Thinking — 100%</h1>
                        <Progress
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                            percent={99.9}
                            />
                            <h1 className="custom-text">Communication — 100%</h1>
                            <Progress
                            strokeColor={{
                                from: '#2CA2FF',
                                to: '#7B32FF',
                            }}
                            percent={99.9}
                            status="active"
                            />
                            <h1 className="custom-text">Research — 100%</h1>
                            <Progress
                            strokeColor={{
                                from: '#7F2EFF',
                                to: '#C528FF',
                            }}
                            percent={99.9}
                            status="active"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Philosophy;