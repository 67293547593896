import React from 'react';
import Hero from '../../layout/pages/hero/index';
import CaseStudy from '../../layout/pages/caseStudy/index';
import Colors from '../../layout/pages/colors/index';
import ProductSpecification from '../../layout/pages/productSpecification/index';
import Uses from '../../layout/pages/uses/index';
import Distinctive from '../../layout/pages/distinctive';
import Philosophy from '../../layout/pages/philosophy/index';
import CoreFeatures from '../../layout/pages/coreFeatures';
import ScrollTop from '../../layout/pages/scrollTopBtn';
import styles from './style.css'; 
const Home=()=>{
    return(
        <>
            <div>
                <ScrollTop/>
                <Hero/>
                <CaseStudy/>
                <Colors/>
                <ProductSpecification/>
                <Uses/>
                <Distinctive/>
                <Philosophy/>
                <CoreFeatures/>
            </div>
        </>
    );
}
export default Home;