import React from 'react';
// import Team from 'http://localhost:3000/team.jpg';
import Team from '../../../assests/images/banner/team.jpg';
import Image1 from '../../../assests/images/banner/img1.jpg';
import styles from './style.css'
const CaseStudy=()=>{
    return(
        <>
            <div className="csMain">
                <div className="csMainInner">
                    <div className="csContentMain flex-md-row flex-sm-column-reverse flex-column-reverse">
                        <div  className="csMainInnerTxt">
                            <div  className="csMainInnerHead">
                                <h1  className="csMainInnerHeadTxt">About The Group</h1>
                            </div>
                            <div className="csMainInnerDesc">
                                <p className="csMainInnerDescTxt">Our group of Companies are leading companies of Pakistan in the fields of Seed (Heera Seed Corporation), Pesticides (Sawera Pesticides Corporation), food (Sawera flour mills), Ginning (Punjnad seed processing plant), Construction (Best see international) and textiles (Sawera textiles private limited). Sawera textiles started business of pp spun bond non woven fabric. With the aim to set highest quality standards in non woven field with Customer satisfaction, quality and innovation are reflected in every aspect of business. Our strength lies in pre-empting customer expectations and product demands. Sawera utilizes the most advanced technology on its production line and retains the modern management system.</p>
                            </div>
                        </div>
                        <div className="csMainInnerImg">
                            <div className="csTeamImgMain">
                                <img mask="url(#mask3)" src={Team} alt="Team Image" className="csTeamImg"/>
                            </div>
                        </div>
                    </div>
                    <div className="csContentMain bottomImgMargin">
                        <div className="csMainInnerImg d-flex align-items-start">
                            <div className="csTeamImgMain csTeamImgMain1">
                                <img mask="url(#mask3)" src={Image1} alt="Team Image" className="csTeamImg"/>
                            </div>
                        </div>
                        <div  className="csMainInnerTxt1">
                            <div  className="csMainInnerHead">
                                <h1  className="csMainInnerHeadTxt">About The Company</h1>
                            </div>
                            <div className="csMainInnerDesc">
                                <p className="csMainInnerDescTxt">Sawera Textiles has largest and advance recofil II German state of art latest production technology System. It produces safe, high quality, high strength & highly hygienic non woven products. A series of quality control measures adopted to ensure top class quality. The Products are according to the requirements of user. By using advance technology of Production, highly qualified & well-trained Staff, Sawera Textiles is the best industry involved in production of Non Woven Fabric in Pakistan. Sawera Spunbond (Non Woven Fabric) 100% Pure Polypropylene Fabric, is currently the most important part for packing/packaging of the products, Surgical Gowns, Face Masks in wide range.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
}
export default CaseStudy;