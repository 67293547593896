import React from 'react';
import Guarantee from '../../../assests/images/banner/guarantee.png';
import styles from './style.css'
const Hero=()=>{
    return(
        <>
            <div className="HeroMain">
                <div className="HeroMainInner">
                    <div className="HeroMainInnerContent">
                        <div className="HeroMainInnerTop">
                            <h5  className="HeroMainInnerTxt1">WORLD'S NO.1 NON WOVEN FABRICS</h5>
                            <h1  className="HeroMainInnerTxt2">SAWERA TEXTILES PVT. LTD.</h1>
                        </div>
                        <div  className="HeroMainInnerBottom">
                            <div  className="HeroMainInnerImageMain">
                                <img src={Guarantee} alt="Guarantee Image"  className="HeroMainInnerImage"/>
                            </div>
                            <div  className="HeroMainInnerImageTxt">
                                <span>“Quality is not an act. It is a habit.”</span>
                            </div>
                        </div>

                    </div>
                </div>
                
            </div>
        </>
    );
}
export default Hero;