import React from 'react';
import BK from '../../../assests/images/collection/BK.png';
import BL from '../../../assests/images/collection/BL.png';
import BR from '../../../assests/images/collection/BR.png';
import BR1 from '../../../assests/images/collection/BR-L.png';
import GR from '../../../assests/images/collection/GR.png';
import GY from '../../../assests/images/collection/GY.png';
import MH from '../../../assests/images/collection/MH.png';
import OG from '../../../assests/images/collection/OG.png';
import PK from '../../../assests/images/collection/PK.png';
import PP from '../../../assests/images/collection/PP.png';
import RD from '../../../assests/images/collection/RD.png';
import YL from '../../../assests/images/collection/YL.png';

import styles from './style.css'
const Colors=()=>{
    return(
        <>
            <div className="clrMain">
                <div className='clrHeadMain'>
                    <h1  className="clrHeadTxt">JUST A FEW FROM A LARGE VERITY OF COLORS</h1>
                </div>
                <div>
                    <div class="bdMain">
                        <div class="flexClsMain animateCarasoul">
                            <div class="flexCls">
                                <div className="clrImgMain"><img src={BK} alt="BK color"/></div>
                                <div className="clrImgMain"><img src={BL} alt="BL color"/></div>
                                <div className="clrImgMain"><img src={BR} alt="BR color"/></div>
                                <div className="clrImgMain"><img src={BR1} alt="BR1 color"/></div>
                                <div className="clrImgMain"><img src={GR} alt="GR color"/></div>
                                <div className="clrImgMain"><img src={GY} alt="GY color"/></div>
                                <div className="clrImgMain"><img src={MH} alt="MH color"/></div>
                                <div className="clrImgMain"><img src={OG} alt="OG color"/></div>
                                <div className="clrImgMain"><img src={PK} alt="PK color"/></div>
                                <div className="clrImgMain"><img src={PP} alt="PP color"/></div>
                                <div className="clrImgMain"><img src={RD} alt="RD color"/></div>
                                <div className="clrImgMain"><img src={YL} alt="YL color"/></div>
                            </div>
                            <div class="flexCls">
                                <div className="clrImgMain"><img src={BK} alt="BK color"/></div>
                                <div className="clrImgMain"><img src={BL} alt="BL color"/></div>
                                <div className="clrImgMain"><img src={BR} alt="BR color"/></div>
                                <div className="clrImgMain"><img src={BR1} alt="BR1 color"/></div>
                                <div className="clrImgMain"><img src={GR} alt="GR color"/></div>
                                <div className="clrImgMain"><img src={GY} alt="GY color"/></div>
                                <div className="clrImgMain"><img src={MH} alt="MH color"/></div>
                                <div className="clrImgMain"><img src={OG} alt="OG color"/></div>
                                <div className="clrImgMain"><img src={PK} alt="PK color"/></div>
                                <div className="clrImgMain"><img src={PP} alt="PP color"/></div>
                                <div className="clrImgMain"><img src={RD} alt="RD color"/></div>
                                <div className="clrImgMain"><img src={YL} alt="YL color"/></div>
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Colors;