import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/pages/home/index';
import Contacts from './components/pages/contact/index';
import Header from './components/layout/header/index';
import Footer from './components/layout/footer/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  return (
   <div>
    <Header/>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
          <Route path="/contact" element={<Contacts />} />
      </Routes>
    </BrowserRouter>
    <Footer/>
   </div>
  );
}

export default App;
