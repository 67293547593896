import React from 'react';
import Logo from '../../assests/images/SAWERA TEXTILES.png';
import styles from './style.css'
const Header=()=>{
    return(
        <>
            <div className="headerMain">
                <div className='d-flex headerMainInner' >
                    <div>
                        <a className="headerLogoImgMain" href="/">
                            <img src={Logo} alt="SAWERA TEXTILES Logo" className="headerLogoImg"/>
                        </a>
                    </div>
                    <div className="headerRight">
                        <div className="headerRightContDetails">
                            <span className="align-middle contactSvgMain"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="25px" height="25px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalPhoneIcon" tabindex="-1" title="LocalPhone"><path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path></svg></span>
                            <span>+923149675190 </span>
                        </div>
                        <div className="headerRightContBtnMain">
                            <a className="headerRightContBtn" href='/contact'>
                                <span>
                                    Contact Us
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Header;