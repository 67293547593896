import React from "react";
import styles from './style.css'; 
import Logo from '../../assests/images/SAWERA TEXTILES.png'
const Contacts=()=>{
    return(
        <div className="contactMain">
            <h1 className="contactMainHeading">Contact Us</h1>
            <div className="contactMainInner">
                <div className="contactContent">
                    <div className="contactContentMain">
                        <h5 className="contactLocationHeading">Head Office Cell (Rahim Yar Khan):</h5>
                        <div className="contactTxt my-sm-2 my-1">+92 311 0051023</div>
                        <div className="contactTxt my-sm-2 my-1" >+92 314 9675620</div>
                        <div className=" contactTxt my-sm-2 my-1" >+92 314 9675621</div>
                    </div>
                    <div className="contactContentMain">
                    <h5 className="contactLocationHeading ">Head Office Phone (Rahim Yar Khan):</h5>
                        <div className=" contactTxt my-sm-2 my-1" >+92 68 5904611</div>
                        <div className="contactTxt my-sm-2 my-1" >+92 68 5904609</div>
                        <div className="contactTxt my-sm-2 my-1" >+92 68 5904603</div>
                    </div>
                    <div className="contactContentMain">
                    <h5 className="contactLocationHeading ">Marketing Representative (Naveed Afzal) :</h5>
                        <div className="contactTxt my-sm-2 my-1">+92 300 8671119</div>
                        <div className="contactTxt my-sm-2 my-1">+92 314 9675619</div>
                    </div>
                    <div className="contactContentMain">
                    <h5 className="contactLocationHeading ">Head Office Cell (Rahim Yar Khan):</h5>
                        <div className="contactTxt my-sm-2 my-1" >+92 314 9675190</div>
                    </div>
                    {/* <div className="d-flex flex-column my-2 bg-white">
                        <h5 className="contactLocationHeading ">Head Office Phone (Rahim Yar Khan):</h5>
                        <div className=" contactTxt my-2" style={{color:'#0d6efd'}}>+92 68 5904611</div>
                        <div className="contactTxt my-2" style={{color:'#0d6efd'}}>+92 68 5904609</div>
                        <div className="contactTxt my-2" style={{color:'#0d6efd'}}>+92 68 5904603</div>
                    </div>
                    <div className="d-flex flex-column my-2 bg-white">
                        <h5 className="contactLocationHeading ">Marketing Representative (Naveed Afzal) :</h5>
                        <div className="contactTxt my-2" style={{color:'#0d6efd'}}>+92 300 8671119</div>
                        <div className="contactTxt my-2" style={{color:'#0d6efd'}}>+92 314 9675619</div>
                    </div>
                    <div className="d-flex flex-column my-2 bg-white">
                        <h5 className="contactLocationHeading ">Head Office Cell (Rahim Yar Khan):</h5>
                        <div className="contactTxt my-2" style={{color:'#0d6efd'}}>+92 314 9675190</div>
                    </div> */}
                </div>
                <div className="mapMain">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14033.547367239169!2d70.309264!3d28.4377529!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4a59517ebb20ef74!2sSawera%20Textiles%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1591941754455!5m2!1sen!2s" width="100%" height="100%" frameborder="0" style={{border:'0'}} allowfullscreen="" aria-hidden={false} tabindex={0}></iframe>
                </div> 
            </div>
        </div>
    )
}
export default Contacts;