import React from 'react';
import TShirt from '../../../assests/svgs/tShirt/index';
import Car from '../../../assests/svgs/car/index';
import styles from './style.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
const Uses=()=>{
    AOS.init({
        once: true,
        mirror: true,
    });
    window.addEventListener('load', AOS.refresh());
    return(
        <>
            <div className="uMain">
                <div>
                    <div className="uMainTop">
                        <div className="uMainHead">
                            <h1 className="uMainHeadTxt">SAWERA TEXTILES <label className="uMainHeadAnimatedTxt animate" data-animate="textDropping2 2s" id="textDrop2">NON WOVEN</label> FABRIC USES</h1>
                            <h5 className="uMainHeadDesc animate" data-animate="fadeInUp 2s">100% PURE FABRIC NO ADDITIVE OR FILLERS ADDED</h5>
                        </div>
                    </div>
                    <div className="uMainBottom">
                        <div className="uMainBottomRow">
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                                data-aos-mirror="true"
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalPharmacyIcon" tabindex="-1" title="LocalPharmacy"><path d="M21 5h-2.64l1.14-3.14L17.15 1l-1.46 4H3v2l2 6-2 6v2h18v-2l-2-6 2-6V5zm-5 9h-3v3h-2v-3H8v-2h3V9h2v3h3v2z"></path></svg></span>
                                        <span className="uCardHeadTxt">Medica & Hygiene</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Surgical Gowns and Gloves</span></li>
                                            <li className="uCardListItem"><span>Face Masks</span></li>
                                            <li className="uCardListItem"><span>Foot Covers</span></li>
                                            <li className="uCardListItem"><span>Diapers</span></li>
                                            <li className="uCardListItem"><span>Caps</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"    
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="LocalMallIcon" tabindex="-1" title="LocalMall"><path d="M19 6h-2c0-2.76-2.24-5-5-5S7 3.24 7 6H5c-1.1 0-1.99.9-1.99 2L3 20c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-7-3c1.66 0 3 1.34 3 3H9c0-1.66 1.34-3 3-3zm0 10c-2.76 0-5-2.24-5-5h2c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 2.76-2.24 5-5 5z"></path></svg></span>
                                        <span className="uCardHeadTxt">Packaging</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Rice bags</span></li>
                                            <li className="uCardListItem"><span>Seed bags</span></li>
                                            <li className="uCardListItem"><span>Pesticides bags</span></li>
                                            <li className="uCardListItem"><span>Fertilizer bags</span></li>
                                            <li className="uCardListItem"><span>Sleeping Bags -Tarpaulins</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="BedIcon" tabindex="-1" title="Bed"><path d="M21 10.78V8c0-1.65-1.35-3-3-3h-4c-.77 0-1.47.3-2 .78-.53-.48-1.23-.78-2-.78H6C4.35 5 3 6.35 3 8v2.78c-.61.55-1 1.34-1 2.22v6h2v-2h16v2h2v-6c0-.88-.39-1.67-1-2.22zM14 7h4c.55 0 1 .45 1 1v2h-6V8c0-.55.45-1 1-1zM5 8c0-.55.45-1 1-1h4c.55 0 1 .45 1 1v2H5V8zm-1 7v-2c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v2H4z"></path></svg></span>
                                        <span className="uCardHeadTxt">Furniture Upholsterx</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Roofing and Tile Underlayment</span></li>
                                            <li className="uCardListItem"><span>Acoustical Ceilings</span></li>
                                            <li className="uCardListItem"><span>Insulation</span></li>
                                            <li className="uCardListItem"><span>House wrap and Pipe wrap</span></li>
                                            <li className="uCardListItem"><span>Sofa and Mattress</span></li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><TShirt/></span>
                                        <span className="uCardHeadTxt">Garments</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Coveralls</span></li>
                                            <li className="uCardListItem"><span>Pillow cases</span></li>
                                            <li className="uCardListItem"><span>Airline Headrests</span></li>
                                            <li className="uCardListItem"><span>Interlinings</span></li>
                                            <li className="uCardListItem"><span>Clothing and Glove insulation</span></li>
                                            <li className="uCardListItem"><span>Bra and Shoulder</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ParkIcon" tabindex="-1" title="Park"><path d="M17 12h2L12 2 5.05 12H7l-3.9 6h6.92v4h3.96v-4H21z"></path></svg></span>
                                        <span className="uCardHeadTxt">Agriculture</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Crop Covers</span></li>
                                            <li className="uCardListItem"><span>Turf Protections</span></li>
                                            <li className="uCardListItem"><span>Nursery OverWintering</span></li>
                                            <li className="uCardListItem"><span>Weed Control Fabrics</span></li>
                                            <li className="uCardListItem"><span>Root Bags</span></li>
                                            <li className="uCardListItem"><span>Containers</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="uCardMain"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                            >
                                <div className="uCardInner">
                                    <div className="uCardHead">
                                        <span className="uCardHeadIcon"><Car/></span>
                                        <span className="uCardHeadTxt">Auto Mobile</span>
                                    </div>
                                    <div className="uCardListMain">
                                        <ul  className="uCardListInner">
                                            <li className="uCardListItem"><span>Auto Covers</span></li>
                                            <li className="uCardListItem"><span>Seat Covers</span></li>
                                            <li className="uCardListItem"><span>Steering wheel Covers</span></li>
                                            <li className="uCardListItem"><span>Auto Filter</span></li>
                                            <li className="uCardListItem"><span>AutoCurtains</span></li>
                                            <li className="uCardListItem"><span>Artificial Leather</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Uses;