import React from "react";

function Car() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54px" height="64px" fill="#31c77f"
      x="0"
      y="0"
      enableBackground="new 0 0 270 270"
      version="1.1"
      viewBox="0 0 270 270"
      xmlSpace="preserve"
    >
      <g>
        <path d="M.002 199.98c0 8.285 6.715 15 15 15H30v20c0 8.283 6.717 15 15 15h50c8.285 0 15-6.717 15-15v-20h50v20c0 8.283 6.717 15 15 15h50c8.285 0 15-6.717 15-15v-20h15a15 15 0 0015-15l-.002-59.286c.035-3.064-.365-21.476-14.173-35.609-7.072-7.239-16.117-11.88-26.973-13.897l-8.327-58.289a15 15 0 00-14.85-12.879H64.324a15 15 0 00-14.85 12.879l-8.327 58.289c-10.854 2.018-19.899 6.658-26.972 13.896C.359 119.228-.034 137.652.002 140.699v59.281zM220 152.501c0 8.284-6.717 14.999-15 14.999-8.285 0-15-6.715-15-14.999 0-8.285 6.715-15 15-15 8.283 0 15 6.715 15 15zM77.333 50.019h115.334l5.714 40H71.619l5.714-40zM65 137.501c8.283 0 15 6.715 15 15 0 8.284-6.717 14.999-15 14.999-8.285 0-15-6.715-15-14.999 0-8.285 6.714-15 15-15z"></path>
      </g>
    </svg>
  );
}

export default Car;