import React from 'react';
import Banner7 from '../../../assests/images/banner/banner7.jpg';
import styles from './style.css'
const ProductSpecification=()=>{
    return(
        <>
            <div className="psMain">
                <div className="psMainInner">
                    <div className="psCont1">
                        <div className="psImageMain">
                            <img src={Banner7} alt="Banner Image" className="psImage"/>
                        </div>
                    </div>
                    <div className="psCont2">
                        <h1 className='psCont2Head'>Product Specification</h1>
                        <div className='psContCard'>
                            <div className="psContCardRow1">
                                <div className="psContCardRowTxt1"><span>Material</span></div>
                                <div className="psContCardRowTxt2"><span>100% Pure PP Homopolymer</span></div>
                            </div>
                            <div className="psContCardRow2">
                                <div className="psContCardRowTxt1"><span>Basic weight</span></div>
                                <div className="psContCardRowTxt2"><span>20 gsm - 120 gsm</span></div>
                            </div>
                            <div className="psContCardRow1">
                                <div className="psContCardRowTxt1"><span>Standard width</span></div>
                                <div className="psContCardRowTxt2"><span>1.6 , 2.4 and 3.2 meter</span></div>
                            </div>
                            <div className="psContCardRow2">
                                <div className="psContCardRowTxt1"><span>Length of Roll</span></div>
                                <div className="psContCardRowTxt2"><span>500 to 7500 Mtrs</span></div>
                            </div>
                            <div className="psContCardRow1">
                                <div className="psContCardRowTxt1"><span>Roll Dia</span></div>
                                <div className="psContCardRowTxt2"><span>25-100 cms</span></div>
                            </div>
                            <div className="psContCardRow2">
                                <div className="psContCardRowTxt1"><span>Roll weight</span></div>
                                <div className="psContCardRowTxt2"><span>10-500 kg</span></div>
                            </div>
                            <div className="psContCardRow1">
                                <div className="psContCardRowTxt1"><span>Core Dia</span></div>
                                <div className="psContCardRowTxt2"><span>2",2.5",3" and 5"</span></div>
                            </div>
                            <div className="psContCardRow2">
                                <div className="psContCardRowTxt1"><span>Treatment</span></div>
                                <div className="psContCardRowTxt2"><span>Hydrophilic, Hydrophobic, UV stabilized treatment.</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ProductSpecification;