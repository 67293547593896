import React,{useState} from 'react';
import styles from './style.css'
const ScrollTop=()=>{
    const [visible, setVisible] = useState(false);
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > window.innerHeight){
          setVisible(true)
        } 
        else if (scrolled <= window.innerHeight){
          setVisible(false)
        }
      };
      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
      
      window.addEventListener('scroll', toggleVisible);
    return(
        <>
            <div className="stMain">
            <button onClick={scrollToTop} 
                className={visible ? 'backTopBtn' : 'd-none'}
                >
                <span className="d-flex justify-content-center align-items-center" >
                <svg viewBox="64 64 896 896" focusable="false" data-icon="arrow-up" width="1em" height="1em" fill="white" aria-hidden="true" style={{fontSize:'20px;'}}><path d="M868 545.5L536.1 163a31.96 31.96 0 00-48.3 0L156 545.5a7.97 7.97 0 006 13.2h81c4.6 0 9-2 12.1-5.5L474 300.9V864c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V300.9l218.9 252.3c3 3.5 7.4 5.5 12.1 5.5h81c6.8 0 10.5-8 6-13.2z"></path></svg>
                </span>
            </button>
            </div>
        </>
    );
}
export default ScrollTop;