import React from "react";

function TShirt() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54px" height="64px" fill="#31c77f"
      x="0"
      y="0"
      enableBackground="new 0 0 974.352 974.352"
      version="1.1"
      viewBox="0 0 974.352 974.352"
      xmlSpace="preserve"
    >
      <path d="M559.572 124.254v32.5c0 39.919-32.478 72.396-72.396 72.396s-72.396-32.477-72.396-72.396v-32.5H268.836L0 402.606l178.26 178.262 71.87-75.197v344.427h474.092V505.669l71.87 75.197 178.26-178.262-268.836-278.35H559.572zm237.571 363.637L659.222 343.583v441.513H315.13V343.583L177.209 487.891l-86.078-86.078 205.293-212.559h57.239c14.64 60.124 68.946 104.896 133.513 104.896s118.873-44.771 133.513-104.896h57.239l205.293 212.559-86.078 86.078z"></path>
    </svg>
  );
}

export default TShirt;