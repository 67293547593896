import React from 'react';
import Feature from '../../../assests/images/banner/feature.png';
import styles from './style.css'
const Distinctive=()=>{
    return(
        <>
            <div className="dMain">
                <div className="dMainInner">
                    <div className="dMainImgSection">
                        <div className="dMainImgMain"
                            data-aos="fade-right"
                            data-aos-once="true"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-out"
                            data-aos-delay="1500"
                            data-aos-duration="1500"
                            data-aos-mirror="true"
                        >
                            <img src={Feature} alt="Feature Image"  className='dMainImg'/>
                        </div>
                    </div>
                    <div className="dMainContent">
                        <div className="dMainHead">
                            <h1 className="dMainHeadTxt">
                                <span
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-offset="200"
                                    data-aos-easing="ease-in-out"
                                    data-aos-delay="1500"
                                    data-aos-duration="1500"
                                    data-aos-mirror="true"
                                >
                                    What makes Us&nbsp;
                                </span>
                                <label className="dMainAnimatedTxt animate" data-animate="textDropping2 2s" id="textDrop1">distinctive</label>
                                <span
                                    data-aos="fade-up"
                                    data-aos-once="true"
                                    data-aos-offset="200"
                                    data-aos-easing="ease-in-out"
                                    data-aos-delay="1500"
                                    data-aos-duration="1500"
                                    data-aos-mirror="true"
                                > ?</span></h1>
                        </div>
                        <div className="dMainCardsMain"
                            data-aos="fade-left"
                            data-aos-once="true"
                            data-aos-offset="200"
                            data-aos-easing="ease-in-out"
                            data-aos-delay="1500"
                            data-aos-duration="1500"
                            data-aos-mirror="true"
                        >
                            <div className="dMainCard">
                                <div className="dMainCardInner">
                                    <div className="dMainCardIconMain">
                                        <span className="dMainCardIconMainInner"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170"  width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PublicIcon" tabindex="-1" title="Public"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"></path></svg></span>
                                    </div>
                                    <div className="dMainCardHeadMain">
                                        <h5 className="dMainCardHeadMainTxt">WORLD'S NO.1 NON WOVEN FABRICS</h5>
                                    </div>
                                </div>
                                <div className="dMainDesc">
                                    <p className="dMainDescTxt">The Company is selling its products in the all over world. We have extensive selling network worldwide.</p>
                                </div>
                            </div>
                            <div className="dMainCard">
                                <div className="dMainCardInner">
                                    <div className="dMainCardIconMain">
                                        <span className="dMainCardIconMainInner"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ThumbUpOffAltIcon" tabindex="-1" title="ThumbUpOffAlt"><path d="m13.11 5.72-.57 2.89c-.12.59.04 1.2.42 1.66.38.46.94.73 1.54.73H20v1.08L17.43 18H9.34c-.18 0-.34-.16-.34-.34V9.82l4.11-4.1M14 2 7.59 8.41C7.21 8.79 7 9.3 7 9.83v7.83C7 18.95 8.05 20 9.34 20h8.1c.71 0 1.36-.37 1.72-.97l2.67-6.15c.11-.25.17-.52.17-.8V11c0-1.1-.9-2-2-2h-5.5l.92-4.65c.05-.22.02-.46-.08-.66-.23-.45-.52-.86-.88-1.22L14 2zM4 9H2v11h2c.55 0 1-.45 1-1v-9c0-.55-.45-1-1-1z"></path></svg></span>
                                    </div>
                                    <div className="dMainCardHeadMain">
                                        <h5 className="dMainCardHeadMainTxt">We’re experts</h5>
                                    </div>
                                </div>
                                <div className="dMainDesc">
                                    <p className="dMainDescTxt">We have expert staff, professional management and efficient network of dedicated Distributors/ Dealers spread all over the world.</p>
                                </div>
                            </div>
                            <div className="dMainCard">
                                <div className="dMainCardInner">
                                    <div className="dMainCardIconMain">
                                        <span className="dMainCardIconMainInner"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CheckCircleOutlineIcon" tabindex="-1" title="CheckCircleOutline"><path d="M16.59 7.58 10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg></span>
                                    </div>
                                    <div className="dMainCardHeadMain">
                                        <h5 className="dMainCardHeadMainTxt">Product Properties</h5>
                                    </div>
                                </div>
                                <div className="dMainDesc">
                                    <p className="dMainDescTxt">Excellent softness, Air permeability, water repellence , Harmlessness, anti bacteria, Good Strength, elongation, Good processing ability, heat-stability.</p>
                                </div>
                            </div>
                            <div className="dMainCard">
                                <div className="dMainCardInner">
                                    <div className="dMainCardIconMain">
                                        <span className="dMainCardIconMainInner"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-1shn170" width="54px" height="64px" fill="#31c77f" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CardMembershipIcon" tabindex="-1" title="CardMembership"><path d="M20 2H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2 4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2zm0 13H4v-2h16v2zm0-5H4V4h16v6z"></path></svg></span>
                                    </div>
                                    <div className="dMainCardHeadMain">
                                        <h5 className="dMainCardHeadMainTxt">100% PURE FABRIC</h5>
                                    </div>
                                </div>
                                <div className="dMainDesc">
                                    <p className="dMainDescTxt">100% pure fabric, no additive or fillers added.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Distinctive;