import React,{useState} from 'react';
import styles from './style.css'
const CoreFeatures=()=>{
    const [openFeature,setOpenFeature]=useState(1);
    return(
        <>
            <div className="cfMain">
                <div className="cfInner">
                    <div className="cfTitleSec">
                        <div className="cfTitleSecMain" >
                            <h1 className="cfTitleSecTxt">Core Features</h1>
                        </div>
                    </div>
                    <div className="cfCardsSec">
                        <div className="cfCardsSecInner">
                            <div className="cfCardMain"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                                data-aos-mirror="true"
                            >
                                <div className="cfCardHead" onClick={()=>{
                                    if(openFeature===1){
                                        setOpenFeature(0)
                                    }
                                    else {
                                        setOpenFeature(1);
                                    }
                                    }}>
                                {(openFeature===1)?
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                                     :
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
                                }
                                <span className="cfCardHeadTxt">Our Mission</span>
                                </div>
                                <div className="cfCardDesc">
                                    <p className={(openFeature===1)?"cfCardDescTxt":"cfCardDescTxtd-none"}>We strive to Constantly generate excellent quality products that meet our highly valued customers' satisfaction through the commitment of our extremely motivated staff'. Our Mission Statement represents the foundation of sawera textiles strategies, in its constant pursuit of excellence when it comes to Product Quality, Customer Satisfaction, Staff Development, Suppliers Selection & Balanced Relationship, and Quality Management System.</p>
                                </div>
                            </div>
                            <div className="cfCardMain"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                                data-aos-mirror="true"
                            >
                            <div className="cfCardHead" onClick={()=>{
                                    if(openFeature===2){
                                        setOpenFeature(0)
                                    }
                                    else {
                                        setOpenFeature(2);
                                    }
                                    }}>
                                {(openFeature===2)?
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                                     :
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
                                }
                                <span className="cfCardHeadTxt">Our Vision</span>
                                </div>
                                <div className="cfCardDesc">
                                    <p className={(openFeature===2)?"cfCardDescTxt":"cfCardDescTxtd-none"}>To preserve our position as one of the leading non woven production facility in the Pakistan producing high quality products for a healthier community. Our endeavor is to maintain an efficiently sound business in order to provide a secured future to our fellow employees, while stressing on the continual improvement of our highly ranked products for a constantly enhanced customer satisfaction.</p>
                                </div>
                            </div>
                            <div className="cfCardMain"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                                data-aos-mirror="true"
                            >
                            <div className="cfCardHead" onClick={()=>{
                                    if(openFeature===3){
                                        setOpenFeature(0)
                                    }
                                    else {
                                        setOpenFeature(3);
                                    }
                                    }}>
                                {(openFeature===3)?
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                                     :
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
                                }
                                <span className="cfCardHeadTxt">Quality Assurance</span>
                                </div>
                                <div className="cfCardDesc">
                                    <p className={(openFeature===3)?"cfCardDescTxt":"cfCardDescTxtd-none"}>Sawera has always placed extreme importance on the quality of the products, with a maximum effort toward meeting the customer's requirement. This involves establishing a continuous relationship with its customers, careful and accurate testing of non woven fabric in the own Laboratory. Direct contact with customer allows our production technologist to personalize and rationalize the fabric according to the customer's requirement.</p>
                                </div>
                            </div>  
                            <div className="cfCardMain"
                                data-aos="fade-left"
                                data-aos-once="true"
                                data-aos-offset="200"
                                data-aos-easing="ease-in-out"
                                data-aos-duration="1500"
                                data-aos-mirror="true"
                            >
                                <div className="cfCardHead" onClick={()=>{
                                    if(openFeature===4){
                                        setOpenFeature(0)
                                    }
                                    else {
                                        setOpenFeature(4);
                                    }
                                    }}>
                                {(openFeature===4)?
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="down" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path></svg></span>
                                     :
                                    <span className="cfCardArrow"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
                                }
                                <span className="cfCardHeadTxt">Pricing</span>
                                </div>
                                <div className="cfCardDesc">
                                    <p className={(openFeature===4)?"cfCardDescTxt":"cfCardDescTxtd-none"}>We can offer the best quality, competitive price and the timely delivery. The quality and infrastructure give as an edge compared to our competitors in all the standards of production.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default CoreFeatures;